import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { ReferralcodePageModule } from './referralcode/referralcode.module';
import { SchedulePageModule } from './schedule/schedule.module';
import { LocadingPageModule } from './locading/locading.module';
//import { BookridePageModule } from './bookride/bookride.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_CONFIG, BaseAppConfig } from './app.config';
import { VtPopupPageModule } from './vt-popup/vt-popup.module';
import { BuyappalertPageModule } from './buyappalert/buyappalert.module';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
//import { Facebook } from '@ionic-native/facebook/ngx'; 
import { Facebook } from '@ionic-native/facebook/ngx'; 
import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
import { environment } from  '../environments/environment';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
 
 

import * as firebase from 'firebase/app';
 

 
export const firebaseConfig = {
  apiKey: "AIzaSyAuDDiHfa3L87YMHasvR81Hwxmj0JptCYc",
  authDomain: "cmt-taxi.firebaseapp.com",
  projectId: "cmt-taxi",
  storageBucket: "cmt-taxi.appspot.com",
  messagingSenderId: "157803619598",
  appId: "1:157803619598:web:e9e3e6b0925cb614b7635f",
  measurementId: "G-552QSPXJSN"
};


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    FormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
     AngularFireDatabaseModule,
     AngularFireAuthModule,
     AngularFireStorageModule,
    IonicStorageModule.forRoot(),
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule,
    ReferralcodePageModule,
    SchedulePageModule,
    LocadingPageModule,
    VtPopupPageModule,
    BuyappalertPageModule,  
   
    
//    BookridePageModule,  
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    FCM,
    Facebook,
    SplashScreen,
    { provide: APP_CONFIG, useValue: BaseAppConfig },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy } ,
    NativeStorage 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
