import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute} from '@angular/router';
import { NavParams,ModalController,NavController, ToastController } from '@ionic/angular';
import { BookridePage } from '../bookride/bookride.page';  
import { DataService } from 'src/app/services/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
 
 
@Component({
  selector: 'app-locading',
  templateUrl: './locading.page.html',
  styleUrls: ['./locading.page.scss'],
})
export class LocadingPage implements OnInit {
   

  constructor(private storage: Storage,private toastCtrl:ToastController, private httpClient : HttpClient,private dataService: DataService,private navParams: NavParams, private router: Router,private route: ActivatedRoute , private modalController: ModalController) { }

  depart: string;
  arriver:any;
  deplng:any;
  deplat:any;
  arrlng:any;
  arrlat:any
  typevoiture:number
  heure:any
  date:any
  post: any;
  prix:any;
  km:any;


 ngOnInit() {
     setTimeout(() => {
        this.bookride();
      }, 2000)

      console.log(this.navParams.get("depart")+"/"+this.navParams.get("arriver")+"/"+this.navParams.get("deplng")+"/"+this.navParams.get("deplat")
    +"/"+this.navParams.get("arrlng")+"/"+this.navParams.get("arrlat")+"/"+this.navParams.get("typevoiture")+"/"+this.navParams.get("heure")+"/"+this.navParams.get("date")+"/"+this.navParams.get("prix")+"/"+this.navParams.get("km"));
  }
    
 dismiss(){
   this.modalController.dismiss();
 }

  bookride() { 

    this.storage.get('session').then((val) => {

    //  console.log('pax', val.pax); 
      
     console.log(this.navParams.get("heure"));
  
   // console.log(this.navParams.get("depart")+'--'+this.navParams.get("deplng")+'--'+this.navParams.get("deplat")+'////'+this.navParams.get("arriver")+this.navParams.get("arrlng")+'--'+this.navParams.get("arrlat"))

    this.dataService.addMissionPost(
      {
         
        "adresse_priseencharge":this.navParams.get("depart"),
        "adresse_depose":this.navParams.get("arriver"),
        "typevehicule":this.navParams.get("typevoiture"),
        "depart_lng":this.navParams.get("deplng"),
        "depart_lat":this.navParams.get("deplat"),
        "arriver_lng":this.navParams.get("arrlng"),
        "arriver_lat":this.navParams.get("arrlat"),
        "datemission":this.navParams.get("date"), 
        "heuremission":this.navParams.get("heure"), 
        "distance_km":this.navParams.get("km"), 
        "montant_km":this.navParams.get("prix").toFixed(2), 
        "pax_id":val.pax,
        
    },
    ).subscribe(
      (data:any)=>{
     
      this.post = data;
      
      if(data.erreur){

        if(data.erreur =="Mission non enregistré point départ ou arrivée vide"){
          this.toastCtrl.create({message :"Mission non enregistré point départ ou arrivée vide ",position:"top",duration:6000,color: 'danger'}).then(t=>t.present());
        

        this.modalController.dismiss();
        this.router.navigate(['./home']); 
        
        }else if(data.erreur =="driver_offline"){

          this.toastCtrl.create({message :"Aucun chauffeur n'est disponible pour cette mission, merci de réessayer plus tard",position:"top",duration:6000,color: 'danger'}).then(t=>t.present());
          this.modalController.dismiss();
          this.router.navigate(['./home']); 
       
        }

      } 
      else{

        console.log(this.post.mission);

        this.toastCtrl.create({message :"Mission est enregistré avec succès",position:"top",duration:6000}).then(t=>t.present());
         
        this.modalController.dismiss();
        this.router.navigate(['./bookride',{id_mission:this.post.mission}]);

        }    
     
      },
    (error)=>{
      console.log(error)
    }
    
    ) 
  
    /*this.modalController.dismiss();
    this.router.navigate(['./bookride']);*/


  });

  }     
}
