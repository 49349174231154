import { Component, OnInit, Inject } from '@angular/core';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { NavController } from '@ionic/angular';
import { APP_CONFIG, AppConfig } from './app.config';
import { MyEvent } from 'src/services/myevent.services';
import { Constants } from 'src/models/contants.models';
import { VtPopupPage } from './vt-popup/vt-popup.page';
import { BuyappalertPage } from './buyappalert/buyappalert.page';
import { Storage } from '@ionic/storage';
import { FCM } from '@awesome-cordova-plugins/fcm/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  datastorage: any;
  pax_name: string;

  rtlSide = "left";
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'home',
      url: '/home',
      icon: 'zmdi zmdi-home'
    }, {
      title: 'my_trips',
      url: '/mytrips',
      icon: 'zmdi zmdi-car-taxi'
    },/* {
      title: 'Fidélité',
      url: '/refer',
      icon: 'zmdi zmdi-balance-wallet'
    },*/{
      title: 'Carte bancaire',
      url: '/addcard',
      icon: 'zmdi zmdi-money'
    },/*
    {
      title: 'Contactez nous',
      url: '/',
      icon: 'zmdi zmdi-comment-more'
    },
    */{
     title: 'change_language',
      url: '/change-language',
      icon: 'zmdi zmdi-globe'
    },
    /*{
      title: 'wallet',
      url: '/wallet',
      icon: 'zmdi zmdi-balance-wallet'
    }, {
      title: 'change_language',
      url: '/change-language',
      icon: 'zmdi zmdi-globe'
    }, {
      title: 'help',
      url: '/help',
      icon: 'zmdi zmdi-alert-circle'
    }, {
      title: 'refer_and_earn',
      url: '/refer',
      icon: 'zmdi zmdi-share'
    }, */{
      title: 'logout',
      url: '/buyappalert',
      icon: 'zmdi zmdi-power'
    },
  ];

  constructor(@Inject(APP_CONFIG) public config: AppConfig,
    private platform: Platform, private modalController: ModalController,
    private splashScreen: SplashScreen, private menu: MenuController,
    private statusBar: StatusBar,
    private fcm: FCM,
    private storage: Storage,
    private translate: TranslateService,
    private navCtrl: NavController, private myEvent: MyEvent) {
    if (this.config.demoMode) setTimeout(() => this.presentModal(), 15000)
    this.initializeApp();
    
    this.myEvent.getLanguageObservable().subscribe(value => {
      this.navCtrl.navigateRoot(['./']);
      this.globalize(value);
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.firebaseNotification();
      let defaultLang = window.localStorage.getItem(Constants.KEY_DEFAULT_LANGUAGE);
      this.globalize(defaultLang);

      this.storage.get("session").then((res)=>{
      
        this.datastorage=res;
        this.pax_name = this.datastorage.name;
 
        console.log("hire" + this.pax_name);
       
       });
       
    });
 
  }

   
  firebaseNotification(){
    this.fcm.onNotification().subscribe(data => {
      if(data.wasTapped){
        console.log("Received in background");
      } else {
        console.log("Received in foreground");
      };
    });
  }

  globalize(languagePriority) {
    this.translate.setDefaultLang("en");
    let defaultLangCode = this.config.availableLanguages[0].code;
    this.translate.use(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
    this.setDirectionAccordingly(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
  }

  setDirectionAccordingly(lang: string) {
    switch (lang) {
      case 'ar': {
        this.rtlSide = "rtl";
        break;
      }
      default: {
        this.rtlSide = "ltr";
        break;
      }
    }
  }

  ngOnInit() {
      setTimeout(() => {
        this.language();
      }, 1000);    
      
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  view_profile() {
    this.navCtrl.navigateRoot(['./profile']);
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: VtPopupPage,
    });
    return await modal.present();
  }

  buyAppAction() {
    this.menu.close();
    this.modalController.create({ component: BuyappalertPage }).then((modalElement) => modalElement.present());
  }
  developed_by() {
    this.menu.close();
    window.open("https://opuslab.works/", '_system', 'location=no');
  }
    
 language() { 
    this.navCtrl.navigateRoot(['./login']);
  }     
}
