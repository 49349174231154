import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class DataService {
   
  posts:any;

 
  
  url:string="https://taxicielcmt.gebanalysis.com"; 
  urlServer:string = "https://taxicielcmt.gebanalysis.com";
  

  constructor(private httpClient : HttpClient) {}



  getChauffeurs() {
    // http://localhost:8000 return this.httpClient.get(this.url+'/api/chauffeurs?is_visible_mobile=true');
    return this.httpClient.get(this.url+'/api/chauffeurs?is_visible_mobile=true');
  }


  getmissionpax(id){
    return this.httpClient.get(this.url+'/api/missions?pax='+id);
  } 

  getmissionpaxcreation(id){
    return this.httpClient.get(this.url+'/api/missions?pax='+id+'&etatMission=10');
  } 

  getmissionpaxplanifier(id){
    return this.httpClient.get(this.url+'/api/missions?pax='+id+'&etatMission=1');
  }

   
  getmissionpaxsuccess(id){
    return this.httpClient.get(this.url+'/api/missions?pax='+id+'&etatMission=4&is_paid=false');
  }

  getmissionpaxPaid(id){
    return this.httpClient.get(this.url+'/api/missions?pax='+id+'&etatMission=4&is_paid=true');
  }


  getmissionpaxannuler(id){
    return this.httpClient.get(this.url+'/api/missions?pax='+id+'&etatMission=5');
  }

  

  getSingleMission(id) {
    return this.httpClient.get(this.url+'/api/missions/'+id); 
  }



  deletemissionpax(id) {
    return this.httpClient.delete(this.url+'/api/missions/'+id);
  }


  getPaxUtilisateur(id){
    return this.httpClient.get(this.url+'/api/users/'+id);
  }

  getPax(id){
    return this.httpClient.get(this.url+'/api/paxes/'+id);
  }

  getPaxMailFacebook(mail){
    return this.httpClient.get(this.url+'/api/paxes?mail='+mail);
  }


  getRatingChauffeur(id){
    return  this.httpClient.get(this.url+'/api/rating_drivers?chauffeur='+id);
  }
  


  
/*
  POST's Requests
*/ 


updatePaxUtilisateur(body:any,id){

  return this.httpClient.put(this.url+'/api/paxes/'+id,body)
}


addClientFacebook(body:any){
  return this.httpClient.post(this.url+'/api/createCompteFacebook',body)
}
 
paiementstripe(body:any){

  return this.httpClient.post(this.url+'/api/Paiementstripe',body)

}


addClientPost(body:any){
  return this.httpClient.post(this.url+'/api/createcompteclient',body)
}


loginClientPax(body:any){
  return this.httpClient.post(this.url+'/api/client/login',body)
}


addMissionPost(body:any){

  return this.httpClient.post(this.url+'/api/newmission',body)
}

addRatingDriverPost(body:any){

  return this.httpClient.post(this.url+'/api/addratingdriver',body)
}
  


}
